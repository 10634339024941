import React, {FC, useCallback} from 'react';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {Counter, CounterSize} from 'wix-ui-tpa/cssVars';
import cx from 'classnames';
import {classes as stylableClasses} from './ProductsQuantity.st.css';
import scssClasses from './ProductQuantity.scss';
import {UserInputType} from '../../../constants';

export enum ProductQuantityDataHooks {
  root = 'product-quantity-root',
}

export interface ProductQuantityProps {
  value: number;
  onChange(value: number): void;
}

const ProductQuantityComponent: FC<ProductQuantityProps & ProvidedGlobalProps & IProvidedTranslationProps> = ({
  t,
  value,
  globals,
  onChange,
}) => {
  const handleInputChange = useCallback(
    (inputValue: string) => {
      const parsedValue = Number(inputValue);
      const validValue = isNaN(parsedValue) || inputValue === '' ? value : parsedValue;
      onChange(validValue);
    },
    [onChange, value]
  );

  const {quantityRange, isProductSubmitted, userInputErrors} = globals;
  /* istanbul ignore next: tested in sled */
  const isError = isProductSubmitted && userInputErrors[UserInputType.Quantity][0];
  /* istanbul ignore next: tested in sled */
  const getErrorMessage = useCallback(() => {
    if (value > quantityRange.max) {
      return t('QUANTITY_EXCEEDS_INVENTORY', {inventory: quantityRange.max});
    } else if (value < quantityRange.min) {
      return t('QUANTITY_BELOW_MINIMUM_RANGE', {minimum: quantityRange.min});
    }
    return null;
  }, [value, quantityRange.max, quantityRange.min, t]);

  const title = t('QUANTITY_LABEL');

  return (
    <Counter
      size={CounterSize.medium}
      className={cx(stylableClasses.counterRoot, scssClasses.counterRoot)}
      data-hook={ProductQuantityDataHooks.root}
      onChange={handleInputChange}
      value={value}
      max={quantityRange.max}
      min={quantityRange.min}
      step={1}
      label={title}
      inputAriaLabel={title}
      incrementAriaLabel={t('productPage.quantity.increase.ariaLabel')}
      decrementAriaLabel={t('productPage.quantity.decrease.ariaLabel')}
      error={isError}
      errorMessage={getErrorMessage()}
      newErrorMessage
    />
  );
};

export const ProductQuantity = withGlobalProps(withTranslations('globals.texts')(ProductQuantityComponent));
